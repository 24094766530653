import React, { PureComponent } from 'react'
import './css/Welcome.css'
import NextSec from '../partials/NextSec'

class Welcome extends PureComponent {
  componentDidMount() {
    let string1 = this.props.data.wlc.str1
    let string2 = this.props.data.wlc.str2
    let hi = document.querySelector('#hi')
    let welcome = document.querySelector('#welcome')
    let i = 0
    setInterval(() => {
      if (i < string1.length) {
        hi.innerHTML += string1[i]
      } else if (i >= string1.length + string2.length) {
        hi.innerHTML = "> "
        welcome.innerHTML = "> "
        i = -1
      } else {
        welcome.innerHTML += string2[i - string1.length]
      }
      i += 1
    }, 100)
  }

  render() {
    return (
      <section id={(this.props.data.header.tabs[0]).toLowerCase()} className='row b-0'>
        <div className='text-center text-white'>
          {this.props.data.wlc.servs.map((x, i) => {
            return <h5 key={x.i} className="w-100" id={"s" + i}>{x.m} <i className={"fa " + x.i} aria-hidden="true"></i></h5>
          })}
          <img src='/me.webp' id='img' alt='me' />
          <h6 className="card-title my-3 row m-0 p-0">
            <span className='col-12 msg' id='hi'>{`> `}</span>
            <span className='col-12 msg' id='welcome'>{`> `}</span>
          </h6>
        </div>
        <NextSec data={this.props.data.header.tabs[1]} />
      </section>
    )
  }
}

export default Welcome