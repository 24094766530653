import React, { PureComponent } from 'react'
import './css/Tab.css'

class Tab extends PureComponent {
  render() {
    return (
      <div className="col-12 tab b-0">
        <a href={"#" + (this.props.tab).toLowerCase()} className='nav-link h-100 b-0 center' onClick={this.props.f}>
          <span className='textTab'>{this.props.tab}</span>
        </a>
      </div>
    )
  }
}

export default Tab