import React, { PureComponent } from 'react'
import NextSec from '../partials/NextSec'
import './css/Works.css'

class Works extends PureComponent {
  render() {
    return (
      <section id={(this.props.data.header.tabs[3]).toLowerCase()} className='row b-0'>
        <div className="card col-11 mx-auto">
          <div className="card-body">
            <h3 className="card-title text-center">{this.props.data.header.tabs[3]}</h3>
            <div className='row center' id='projects'>
              {this.props.data.works.p.map(x => {
                return (
                  <div key={x.name} className='project col-auto m-1'>
                    <a className='row rounded-3 center al' target='_black' rel="noreferrer" href={x.s}>
                      <img loading='lazy' src={"/works/" + x.name.replace(" ", "").toLowerCase() + ".webp"} className='col-auto' alt={x.name} width="192" height="108" />
                      <span className='col-12 center'>{x.name}</span>
                    </a>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
        <NextSec data={this.props.data.header.tabs[4]} />
      </section>
    )
  }
}

export default Works