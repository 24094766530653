import React, { PureComponent } from 'react'
import './css/About.css'
import NextSec from '../partials/NextSec'

class About extends PureComponent {
  render() {
    return (
      <section id={(this.props.data.header.tabs[1]).toLowerCase()} className='row b-0'>
        <div className="card col-12 col-md-6 card-dark center" id='cardInfo'>
          <img src='/me.webp' alt='me' id='img' className='img-card-top' />
          <div className="card-body">
            <h5 className="card-title text-center">Santi</h5>
            <h6 className="card-subtitle mb-2 text-muted text-center">{this.props.data.about.sub}</h6>
            <div className='row' id='info'>
              {this.props.data.about.info.map((x, i) => {
                if (x.href === false) {
                  return (
                    <div key={i} className='col-12'>
                      <span className='cat'>{x.l}</span>:
                      <span className={x.t}>{x.val}</span>
                    </div>
                  )
                } else {
                  return (
                    <div key={i} className='col-12'>
                      <span className='cat'>{x.l}</span>:
                      <span className={x.t}>
                        <a href={x.href} className={x.t}>{x.val}</a>
                      </span>
                    </div>
                  )
                }
              })}
            </div>
          </div>
        </div>
        <NextSec data={this.props.data.header.tabs[2]} />
      </section>
    )
  }
}

export default About