import React, { Component } from 'react'
import './css/Medias.css'

export default class Medias extends Component {
  render() {
    return (
      <div id='medias'>
        <a href="https://wa.link/rx44iv" target='_blank' rel="noreferrer">
          <span className='fa-brands fa-square-whatsapp text-white'></span>
        </a>
        <a href='mailto:devsanti.dev@gmail.com' target='_blank' rel="noreferrer">
          <span className='fa fa-square-envelope text-white'></span>
        </a>
        <a href="tel:+573206452482">
          <span className="fa fa-square-phone text-white"></span>
        </a>
      </div>
    )
  }
}
