import { Routes, Route } from 'react-router-dom';
import './App.css'
import Home from './routes/Home';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";



const App = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyCiIBhSPy9-uK6uDsBYmxJNjc4Xc_lEulE",
    authDomain: "devsanti-react-2023-v1.firebaseapp.com",
    projectId: "devsanti-react-2023-v1",
    storageBucket: "devsanti-react-2023-v1.appspot.com",
    messagingSenderId: "59649202400",
    appId: "1:59649202400:web:480b137bf71b0774d79885",
    measurementId: "G-V1VK7XWRJH"
  };
  const app = initializeApp(firebaseConfig);
  getAnalytics(app);
  const data = {
    langs: {
      es: {
        lang: "es",
        header: {
          tabs: ["Inicio", "Sobre", "Habilidades", "Trabajos", "Reseñas"]
        },
        wlc: {
          str1: "¡Hola!",
          str2: "Bienvenido a mi portafolio. ",
          servs: [
            {
              m: "Programo",
              i: "fa-code"
            },
            {
              m: "Desarrollo web",
              i: "fa-layer-group"
            },
            {
              m: "Diseño web",
              i: "fa-pen-nib"
            },
          ]
        },
        about: {
          sub: 'Desarrollador y Diseñador web',
          info: [
            {
              l: "Correo",
              t: "str",
              val: "'devsanti.dev@gmail.com'",
              href: "mailto:devsanti.dev@gmail.com"
            },
            {
              l: "Tel",
              t: "int",
              val: "+57 320 645 2482",
              href: "tel:+573206452482",
            },
            {
              l: "Ubicación",
              t: "str",
              val: "'Medellín, Colombia'",
              href: false
            },
            {
              l: "Nacionalidades",
              t: "list",
              val: "['Venezolano','Italiano']",
              href: false
            },
            {
              l: "Lenguas",
              t: "list",
              val: "['Español Nativo','Francés B2','Inglés A2']",
              href: false
            },
          ]
        },
        works: {
          p: [
            {
              name: "Anchor Advisory",
              s: "https://anchor-advisory.web.app/en/home"            },
          ]
        },
        reviews: [
          {
            name: "Luis A. Dubon",
            m: '"Nostrud quis veniam consequat aliquip in eiusmod proident eiusmod pariatur incididunt quis enim adipisicing. Amet cillum aliquip velit ad consectetur elit consectetur fugiat reprehenderit aliqua voluptate. Et pariatur quis ea cupidatat consequat in voluptate ullamco nisi sunt nisi."'
          },
        ],
      },
      fr: {
        lang: "fr",
        header: {
          tabs: ["Accueil", "Moi", "Skills", "Travaux", "Reviews"]
        },
        wlc: {
          str1: "Salut!",
          str2: "Bienvenue dans mon portfolio.",
          servs: [
            {
              m: "Programme",
              i: "fa-code"
            },
            {
              m: "Développement web",
              i: "fa-layer-group"
            },
            {
              m: "Web Design",
              i: "fa-pen-nib"
            },
          ]
        },
        about: {
          sub: 'Développeur et Dessinateur web',
          info: [
            {
              l: "Mail: ",
              t: "str",
              val: "'devsanti.dev@gmail.com'",
              href: "mailto:devsanti.dev@gmail.com"
            },
            {
              l: "Télephone: ",
              t: "int",
              val: "+57 320 645 2482",
              href: "tel:+573206452482",
            },
            {
              l: "Emplacement: ",
              t: "str",
              val: "'Medellin, Colombia'",
              href: false
            },
            {
              l: "Nationalités: ",
              t: "list",
              val: "['Vénézuélien','Italien']",
              href: false
            },
            {
              l: "Langues: ",
              t: "list",
              val: "['Espagnol Natif','Français B2','Anglais A2']",
              href: false
            },
          ]
        },
        works: {
          p: [
            {
              name: "Anchor Advisory",
              s: "https://anchor-advisory.web.app/en/home"
            },
          ]
        },
        reviews: [
          {
            name: "Luis A. Dubon",
            m: '"Nostrud quis veniam consequat aliquip in eiusmod proident eiusmod pariatur incididunt quis enim adipisicing. Amet cillum aliquip velit ad consectetur elit consectetur fugiat reprehenderit aliqua voluptate. Et pariatur quis ea cupidatat consequat in voluptate ullamco nisi sunt nisi."'
          },
        ],
      },
      en: {
        lang: "en",
        header: {
          tabs: ["Home", "About", "Skills", "Works", "Reviews"]
        },
        wlc: {
          str1: "Hi!",
          str2: "Welcome to my portfolio. ",
          servs: [
            {
              m: "Program",
              i: "fa-code"
            },
            {
              m: "Web Development",
              i: "fa-layer-group"
            },
            {
              m: "Web Design",
              i: "fa-pen-nib"
            },
          ]
        },
        about: {
          sub: 'Web Developer/Designer',
          info: [
            {
              l: "Email",
              t: "str",
              val: "'devsanti.dev@gmail.com'",
              href: "mailto:devsanti.dev@gmail.com"
            },
            {
              l: "Tel",
              t: "int",
              val: "+57 320 645 2482",
              href: "tel:+573206452482",
            },
            {
              l: "City: ",
              t: "str",
              val: "'Medellin, Colombia'",
              href: false
            },
            {
              l: "Nationalities: ",
              t: "list",
              val: "['Venezuelan','Italian']",
              href: false
            },
            {
              l: "Languages: ",
              t: "list",
              val: "['Spanish Native','French B2','English A2']",
              href: false
            },
          ]
        },
        works: {
          p: [
            {
              name: "Anchor Advisory",
              s: "https://anchor-advisory.web.app/en/home"
            },
          ]
        },
        reviews: [
          {
            name: "Luis A. Dubon",
            m: '"Nostrud quis veniam consequat aliquip in eiusmod proident eiusmod pariatur incididunt quis enim adipisicing. Amet cillum aliquip velit ad consectetur elit consectetur fugiat reprehenderit aliqua voluptate. Et pariatur quis ea cupidatat consequat in voluptate ullamco nisi sunt nisi."'
          },
        ],
      },
    },
  }


  return (
    <>
      <Routes>
        <Route path="/es" element={<Home data={data.langs.es} />} />
        <Route path="/fr" element={<Home data={data.langs.fr} />} />
        <Route path="/en" element={<Home data={data.langs.en} />} />
      </Routes>
    </>
  );
};

export default App;