import React, { Component } from 'react'
import './css/Header.css'
import Medias from './Medias'
import Tab from './header/Tab'
import Flag from './header/Flag'

export default class Header extends Component {
  constructor(props) {
    super(props)
    this.ls = ["es", "fr", "en"]
  }

  componentDidMount() {
    let rm = true
    let raya = document.getElementById('raya')
    setInterval(() => {
      if (rm === true) {
        raya.innerText = " "
        rm = false
      } else {
        raya.innerText = "_"
        rm = true
      }
    }, 700)
  }
  show = () => {
    let drop = document.getElementById("menuCollapse").classList
    let icon = document.getElementById("btnMenuIcon").classList

    if (drop.contains('d-no')) {
      drop.remove("d-none")
      drop.replace("d-no", "d-yes")
      icon.replace("fa-bars", "fa-xmark")
    } else {
      drop.replace("d-yes", "d-no")
      icon.replace("fa-xmark", "fa-bars")
      setTimeout(() => {
        drop.add("d-none")
      }, 700);
    }
  }
  render() {
    return (
      <nav className="navbar fixed-top navbar-dark p-0 border-bottom border-success">
        <div className='centerY col'>
          <a className="navbar-brand m-0 mx-2" href={"/" + this.props.data.lang}>dev<span id='raya'> </span>santi</a>
        </div>
        <button id='btnMenu' className='btn center col-auto' onClick={this.show} aria-label='Menu'>
          <i className="fa fa-bars" id='btnMenuIcon' aria-hidden="true"></i>
        </button>
        <div id='menuCollapse' className='col-12 row b-0 d-no d-none'>
          {this.props.data.header.tabs.map(x => {
            return <Tab key={x} f={this.show} tab={x} />
          })}
          <div className="col-12 row b-0 tab">
            <div className='col-12 row b-0 center'>
              {this.ls.map(x => {
                return <Flag key={x} l={x} />
              })}
            </div>
            <div className='col-12 b-0 center'>
              <Medias></Medias>
            </div>
          </div>
        </div>
      </nav>
    )
  }
}
