import React, { Component } from 'react'
import './Home.css'
import Header from '../components/partials/Header'
import Welcome from '../components/sections/Welcome'
import About from '../components/sections/About'
import Skills from '../components/sections/Skills'
import Works from '../components/sections/Works'
import Reviews from '../components/sections/Reviews'
import Footer from '../components/partials/Footer'

export default class Inicio extends Component {
  constructor(props) {
    super(props)
    this.lengs = this.props.lengs
  }

  componentDidMount() {
    if (window.scrollY !== 0) {
      window.scrollTo(0, 0)
    }
    document.location.href = "#" + this.props.data.header.tabs[0].toLowerCase()
    document.getElementsByTagName('html')[0].lang = this.props.data.lang
  }
  render() {
    return (
      <div className='bg-gradiente'>
        <Header data={this.props.data} />
        <Welcome data={this.props.data} />
        <About data={this.props.data} />
        <Skills data={this.props.data} />
        <Works data={this.props.data} />
        <Reviews data={this.props.data} />
        <Footer data={this.props.data} />
      </div>
    )
  }
}