import React, { PureComponent } from 'react'
import './css/Reviews.css'

class Reviews extends PureComponent {

  render() {
    return (
      <section id={this.props.data.header.tabs[4].toLowerCase()} className='row b-0 center'>
        <div className="card card-dark col-11 mx-auto b-0">
          <div className="card-body">
            <h3 className="card-title center">{this.props.data.header.tabs[4]}</h3><br />
            <div className='row center'>
              {this.props.data.reviews.map((x, i) => {
                return (
                  <div className='col col-md-6 border border-light rounded-3 p-3 m-3' key={i}>
                    <h4 className='card-title m-0'>{x.name}</h4>
                    <a href={this.props.data.works.p[i].s} target='_blank' rel="noreferrer" className='card-subtitle text-white text-muted'>{this.props.data.works.p[i].name}</a><br /><br />
                    <p className='card-text'>{x.m}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Reviews