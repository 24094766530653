import React, { PureComponent } from 'react'
import './css/NextSec.css'

class NextSec extends PureComponent {
  render() {
    return (
      <a href={"#" + (this.props.data).toLowerCase()} className='col-12 center bg-transparent border-0 nextSec' aria-label={"Go to" + this.props.data.toLowerCase()}>
        <i className='fa fa-chevron-down'></i>
      </a>
    )
  }
}

export default NextSec