import React, { PureComponent } from 'react'
import './css/Flag.css'

class Flag extends PureComponent {
  render() {
    return (
      <div className='col-auto b-0'>
        <a href={"/" + this.props.l} className='nav-link px-2'>
          <img src={"/flags/" + this.props.l + ".png"} className='flag' alt={this.props.l} width="25" height="25"/>
        </a>
      </div>
    )
  }
}

export default Flag