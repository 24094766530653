import React, { PureComponent } from 'react'
import './css/Skills.css'
import NextSec from '../partials/NextSec'

class Skills extends PureComponent {
  lengs = [
    {
      n: 'VSCode',
      t: 'url',
    },
    {
      n: 'HTML',
      t: 'icon',
      i: 'html5'
    },
    {
      n: 'CSS',
      t: 'icon',
      i: 'brands fa-css3-alt'
    },
    {
      n: 'Sass',
      t: 'icon',
      i: 'sass'
    },
    {
      n: 'Bootstrap',
      t: 'icon',
      i: 'bootstrap'
    },
    {
      n: 'Font Awesome',
      t: 'icon',
      i: 'font-awesome'
    },
    {
      n: 'Python',
      t: 'icon',
      i: 'python'
    },
    {
      n: 'JS',
      t: 'icon',
      i: 'js'
    },
    {
      n: 'TS',
      t: 'url',
    },
    {
      n: 'JSON',
      t: 'url',
    },
    {
      n: 'JQuery',
      t: 'url',
    },
    {
      n: 'Firebase',
      t: 'url',
    },
    {
      n: 'Angular',
      t: 'icon',
      i: 'angular'
    },
    {
      n: 'React',
      t: 'icon',
      i: 'react'
    },
    {
      n: 'Vue',
      t: 'icon',
      i: 'vuejs'
    },
    {
      n: 'Node.js',
      t: 'icon',
      i: 'node-js'
    },
    {
      n: 'EJS',
      t: 'url',
    },
    {
      n: 'Express',
      t: 'url',
    },
    {
      n: 'Sails',
      t: 'url',
    },
    {
      n: 'Heroku',
      t: 'url',
    },
    {
      n: 'NodeMailer',
      t: 'url',
    },
    {
      n: 'MySQL',
      t: 'url',
    },
    {
      n: 'PostgreeSQL',
      t: 'url',
    },
    {
      n: 'MongoDB',
      t: 'url',
    },
    {
      n: 'CouchDB',
      t: 'url',
    },
    {
      n: 'GIT',
      t: 'icon',
      i: 'git-alt'
    },
    {
      n: 'Github',
      t: 'icon',
      i: 'github'
    },
    {
      n: 'NPM',
      t: 'icon',
      i: 'npm'
    },
    {
      n: 'YARN',
      t: 'icon',
      i: 'yarn'
    },
  ]
  render() {
    return (
      <section id={(this.props.data.header.tabs[2]).toLowerCase()} className='row b-0'>
        <div className="card col-11 mx-auto">
          <div className="card-body">
            <h3 className="card-title text-center">{this.props.data.header.tabs[2]}</h3>
            <div className='row center' id='techs'>
              {this.lengs.map(x => {
                if (x.t === "url") {
                  return <div key={x.n} className='leng col-auto text-center'><img id={x.n} src={"/skills/" + x.n.toLowerCase() + ".webp"} height="40" width="40" alt={x.n} /><span className='tLeng'>{x.n}</span></div>
                } else {
                  return <div key={x.n} className='leng col-auto text-center'><i className={"fab fa-" + x.i}></i><span className='tLeng'>{x.n}</span></div>
                }
              })}
            </div>
          </div>
        </div>
        <NextSec data={this.props.data.header.tabs[3]} />
      </section>
    )
  }
}


export default Skills