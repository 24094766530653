import React, { PureComponent } from 'react'
import Flag from './header/Flag'
import './css/Footer.css'

class Footer extends PureComponent {
  ls = ["es", "fr", "en"]
  render() {
    return (
      <footer className="bg-1 text-white w-100 row m-0 pb-0 centerX">
        <ul className="col-12 col-md-auto pt-5">
          {this.props.data.header.tabs.map(x => {
            return (
              <li key={x.toLowerCase()}><a href={x.toLowerCase()} className="text-white text-decoration-none">{x}</a></li>
            )
          })}
        </ul>
        <ul className="col-12 col-md-auto pt-5">
          <li><span className="fa fa-envelope"></span> | <a href="mailto:devsanti.dev@gmail.com" className="text-white text-decoration-none">devsanti.dev@gmail.com</a></li>
          <li><span className="fa fa-phone"></span> | <a href="tel:+573206452482" className="text-white text-decoration-none">+57 320 645 2482</a></li>
        </ul>
        <div className="col-12">
          <div className='col-12 row b-0 center'>
            {this.ls.map(x => {
              return <Flag key={x} l={x} />
            })}
          </div>
        </div>
        <div className="col-12 h-auto center fw-lighter">
          Copyright<span className="fa-regular fa-copyright m-1"></span>2023 Handcrafted by Santi
        </div>
      </footer>
    )
  }
}

export default Footer